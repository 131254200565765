<template>
  <div class="app-container h">
    <!-- 左侧树 -->
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">直营门店</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toQuery" />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>
        <el-form-item label="商品范围" class="filter-item">
          <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
          <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
        </el-form-item>
        <el-form-item label="对比类型" class="filter-item">
          <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类1" style="width: 150px;" :label.sync="type1Name" />
          <tree-picker v-model="query.categoryIdB" url="api/category/tree" clearable placeholder="请选择分类2" style="width: 150px;" :label.sync="type2Name" />
        </el-form-item>
        <el-form-item label="分组方式" class="filter-item">
          <el-select v-model="query.shopAreaGroup" placeholder style="width: 150px;">
            <el-option value="area" label="区域"></el-option>
            <el-option value="shop" label="店铺"></el-option>
            <el-option value="salesman" label="销售人员"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <el-collapse :value="['chart', 'data']">
          <el-collapse-item title="分析图表" name="chart">
            <div v-show="store && store.length">
              <el-form class="head-container e" label-position="right" label-width="138px">
                <el-form-item label="分析对象:" class="filter-item">
                  <el-radio-group v-model="query.isSendObject" size="mini" @change="draw">
                    <el-radio-button :label="true">出库量</el-radio-button>
                    <el-radio-button :label="false">销售量</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="排序依据:" class="filter-item">
                  <el-radio-group v-model="query.isGoodsMoney" size="mini" @change="draw">
                    <el-radio-button :label="true">商品金额</el-radio-button>
                    <el-radio-button :label="false">商品数量</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="显示图表前：" class="filter-item">
                  <el-input-number v-model="chartSize" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="draw" />&nbsp;名
                </el-form-item>
              </el-form>
              <div ref="chart"></div>
            </div>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table row-key="id" border :data="data" size="small" height="530">
                <el-table-column prop="name" label="名称" width="150" show-overflow-tooltip />
                <el-table-column label="销售金额" align="center">
                  <el-table-column prop="saleMoneyA" label="金额" :formatter="this.$price" min-width="150" />
                  <el-table-column prop="saleMoneyB" label="对比金额" :formatter="this.$price" min-width="150" />
                  <el-table-column prop="saleMoneyRatio" label="配比" min-width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.saleMoneyRatio">{{ (scope.row.saleMoneyRatio).toFixed(2)}}</span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="销售数量" align="center">
                  <el-table-column prop="saleNumberA" label="数量" min-width="150" />
                  <el-table-column prop="saleNumberB" label="对比数量" min-width="150" />
                  <el-table-column prop="saleNumberRatio" label="配比" min-width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.saleNumberRatio">{{ (scope.row.saleNumberRatio).toFixed(2)}}</span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="发货金额" align="center">
                  <el-table-column prop="sendMoneyA" label="金额" :formatter="this.$price" min-width="150" />
                  <el-table-column prop="sendMoneyB" label="对比金额" :formatter="this.$price" min-width="150" />
                  <el-table-column prop="sendMoneyRatio" label="配比" min-width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.sendMoneyRatio">{{ (scope.row.sendMoneyRatio).toFixed(2)}}</span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="发货数量" align="center">
                  <el-table-column prop="sendNumberA" label="数量" min-width="150" />
                  <el-table-column prop="sendNumberB" label="对比数量" min-width="150" />
                  <el-table-column prop="sendNumberRatio" label="配比" min-width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.sendNumberRatio">{{ (scope.row.sendNumberRatio).toFixed(2)}}</span>
                      <span v-else>--</span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
              <el-pagination align="center" :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" />
            </template>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import goodsSelector from "@/views/assembly/goodsSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  name: "trend",
  components: {
    deptSelect,
    goodsSelector,
  },
  data() {
    let now = new Date(this.$now.get());
    return {
      loading: false,
      goodsSpuName: null,
      store: [],
      chartTypes: "zzt",
      page: 1,
      size: 10,
      chartSize: 5,
      chart: null,
      type1Name: null,
      type2Name: null,
      query: {
        shopAreaGroup: "area",
        isGoodsMoney: true,
        isSendObject: false,
        treeNodeId: null,
        treeNodeType: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        yearOnYearShow: false,
        compareYear: new Date(
          now.getFullYear() - 1,
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        compareMonth: false,
        goods: null,
        skuId: null,
        brandIds: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
      downloadLoading: false,
    };
  },

  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    searchable() {
      return (
        this.query.treeNodeId &&
        this.query.begDate &&
        this.query.endDate &&
        this.query.shopAreaGroup
      );
    },
  },
  methods: {
    handleGoodsChange(res) {
      if (res && res.spu) {
        this.query.goods = res.spu;
      }
    },
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      let sd = new Date(data.compareYear);
      if (data.goods) data.skuId = data.goods.id;
      delete data.goods;
      delete data.compareYear;
      return data;
    },
    toQuery() {
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/goods/contrast",
        method: "get",
        params: data,
      }).then((res) => {
        this.loading = false;
        this.store = res;
        this.$nextTick((_) => {
          this.draw();
        });
      });
    },
    draw() {
      if (this.store && this.store.length) {
        let series = [];
        let legend = [];
        let xAxisData = [];
        let aCol = {};
        let bCol = {};
        let cCol = {};
        let yName = "";
        let yFormat = this.$price;

        if (this.query.isSendObject == true) {
          if (this.query.isGoodsMoney == true) {
            aCol.text = "类型1发货金额";
            aCol.key = "sendMoneyA";
            bCol.text = "类型2发货金额";
            bCol.key = "sendMoneyB";
            cCol.text = "发货金额配比";
            cCol.key = "sendMoneyRatio";
            yName = "元";
            yFormat = this.$price;
          } else {
            aCol.text = "类型1发货数量";
            aCol.key = "sendNumberA";
            bCol.text = "类型2发货数量";
            bCol.key = "sendNumberB";
            cCol.text = "发货数量配比";
            cCol.key = "sendNumberRatio";
            yName = "件";
            yFormat = "{value}";
          }
        } else {
          if (this.query.isGoodsMoney == true) {
            aCol.text = "类型1销售金额";
            aCol.key = "saleMoneyA";
            bCol.text = "类型2销售金额";
            bCol.key = "saleMoneyB";
            cCol.text = "销售金额配比";
            cCol.key = "saleMoneyRatio";
            yName = "元";
            yFormat = this.$price;
          } else {
            aCol.text = "类型1销售数量";
            aCol.key = "saleNumberA";
            bCol.text = "类型2销售数量";
            bCol.key = "saleNumberB";
            cCol.text = "销售数量配比";
            cCol.key = "saleNumberRatio";
            yName = "件";
            yFormat = "{value}";
          }
        }

        this.store = this.store.sort((a, b) => {
          return (b[cCol.key] || 0) - (a[cCol.key] || 0);
        });

        legend.push(aCol.text);
        legend.push(bCol.text);
        legend.push(cCol.text);
        let nodeA = {
          name: aCol.text,
          type: "bar",
          stack: "合计",
          data: [],
        };
        let nodeB = {
          name: bCol.text,
          type: "bar",
          stack: "合计",
          data: [],
        };
        let nodeC = {
          name: cCol.text,
          yAxisIndex: 1,
          type: "line",
          data: [],
        };

        let ds = [].concat(this.store);
        if (this.chartSize < ds.length) ds.length = this.chartSize;

        ds.forEach((d) => {
          xAxisData.push(d.name.length > 10 ? d.name.slice(0, 10) : d.name);
          if (yName === "元") {
            let a = (d[aCol.key] || 0) / 100;
            a = a.toFixed(2);
            nodeA.data.push(a);
            a = (d[bCol.key] || 0) / 100;
            a = a.toFixed(2);
            nodeB.data.push(a);
          } else {
            let a = d[aCol.key] || 0;
            a = a.toFixed(2);
            nodeA.data.push(a);
            a = d[bCol.key] || 0;
            a = a.toFixed(2);
            nodeB.data.push(a);
          }

          let c = d[cCol.key] || 0;
          c = c.toFixed(2);
          nodeC.data.push(c);
        });

        series.push(nodeA);
        series.push(nodeB);
        series.push(nodeC);

        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 420,
          });
        }

        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: legend,
          },
          grid: {
            y2: 150,
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisLabel: {
                interval: 0,
                formatter: function (value) {
                  return value.split("").join("\n");
                },
              },
            },
          ],
          // dataZoom: [
          //   {
          //     type: "slider",
          //     show: true,
          //     xAxisIndex: [0],
          //     start: 1,
          //     end: 15,
          //   },
          // ],
          yAxis: [
            {
              type: "value",
              name: yName,
              axisLabel: {
                formatter: yFormat,
              },
            },
            {
              type: "value",
              name: "配比（倍）",
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],

          series: series,
        });
      } else {
        this.chart = null;
      }
    },
    toDownload() {
      let params = this.makeParams();
      this.downloadLoading = true;
      download("@host:analysis;api/analy/shop/download/goodsContrast", params)
        .then((result) => {
          downloadFile(result, "商品对比分析", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

